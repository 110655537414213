import React from 'react';
import {
  Avatar,
  Button,
  Container,
  Grid,
  Paper,
  Typography,
} from '@mui/material';

export default function Profile() {
  return (
    <Container style={{ marginTop: '100px' }}>
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Paper style={{ padding: '20px', textAlign: 'center' }}>
          
          <Typography variant="h5" gutterBottom>
            John Doe
          </Typography>
          <Typography variant="subtitle1" gutterBottom>
            john.doe@example.com
          </Typography>
          <Button variant="contained" color="primary">
            Edit Profile
          </Button>
        </Paper>
      </Grid>
      <Grid item xs={12}>
        <Paper style={{ padding: '20px' }}>
          <Typography variant="h6" gutterBottom>
            About Me
          </Typography>
          <Typography variant="body1" gutterBottom>
           I am a very positive and enthusiastic. Every challenge I face is an opportunity to learn and grow. Believe in yourself, keep trying, and you'll achieve amazing things.
          </Typography>
        </Paper>
      </Grid>
      <Grid item xs={12}>
        <Paper style={{ padding: '20px' }}>
          <Typography variant="h6" gutterBottom>
            Interests
          </Typography>
          <Typography variant="body1" gutterBottom>
            - Sports
            <br />
            - Photography
            <br />
            - Reading
          </Typography>
        </Paper>
      </Grid>
    </Grid>
  </Container>
  )
}
