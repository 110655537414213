export const quizData = [
    {
      "no": 1,
      "question": "Which of the following sequence of instructions does the Control Unit Perform?",
      "a": "Decodes, Executes and Fetches",
      "b": "Fetches, Decodes and Executes",
      "c": "Executes, Decodes and Fetches",
      "d": "Fetches, Executes and Decodes",
      "correct": "b"
    },
    {
      "no": 2,
      "question": "The data from the form above is captured by",
      "a": "MICR",
      "b": "OCR",
      "c": "OMR",
      "d": "USB",
      "correct": "c"
    },
    {
      "no": 3,
      "question": "A diskette is divided into a number of concentric circles called",
      "a": "Sectors",
      "b": "Cylinders",
      "c": "Tracks",
      "d": "Clusters",
      "correct": "c"
    },
    {
      "no": 4,
      "question": "The storage capacity of a Floppy Disk is",
      "a": "1.44 MB",
      "b": "1.44 GB",
      "c": "1440 KB",
      "d": "1440 MB",
      "correct": "a"
    },
    {
      "no": 5,
      "question": "The four-bit binary representation of the decimal number 8 is",
      "a": "0001",
      "b": "0010",
      "c": "0100",
      "d": "1000",
      "correct": "d"
    },
    {
      "no": 6,
      "question": "The barcode reader in a device used to",
      "a": "Input data",
      "b": "Output data",
      "c": "Store data",
      "d": "none of the above",
      "correct": "a"
    },
    {
      "no": 7,
      "question": "Which of the following devices is NOT used in point-of-sale terminals?",
      "a": "Printer",
      "b": "Plotter",
      "c": "Barcode Reader",
      "d": "Electronic Cash Register",
      "correct": "b"
    },
    {
      "no": 8,
      "question": "The one’s complement of 01010011 is",
      "a": 11010011,
      "b": 1011111,
      "c": 10101100,
      "d": 11111111,
      "correct": "c"
    },
    {
      "no": 9,
      "question": "In modern personal computers, the CPU speed is measured in",
      "a": "Nanoseconds",
      "b": "Milliseconds",
      "c": "MHz",
      "d": "GHz",
      "correct": "d"
    },
    {
      "no": 10,
      "question": " Which of the following primary storage devices can be programmed only once?",
      "a": "RAM",
      "b": "PROM",
      "c": "EPROM",
      "d": "EEPROM",
      "correct": "b"
    },
    {
      "no": 11,
      "question": "A wedge-shaped slice on a disk is referred to as a",
      "a": "Buffer",
      "b": "Cylinder",
      "c": "Sector",
      "d": "Track",
      "correct": "c"
    },
    {
      "no": 12,
      "question": "Which of the following units performs mathematical calculations?",
      "a": "Control",
      "b": "Fetch",
      "c": "Arithmetic and Logic",
      "d": "Central Processing",
      "correct": "c"
    },
    {
      "no": 13,
      "question": "Which of the following actions must be taken to get to Record number 50 of 100 records on a Floppy Disk?",
      "a": "Convert to tape",
      "b": "Fast forward to record 100, and then read backward",
      "c": "First read records 1 through 49",
      "d": "Go directly to record 50",
      "correct": "c"
    },
    {
      "no": 14,
      "question": "Which of the following is NOT true of Secondary Storage media?",
      "a": "Pictures and Text can be stored",
      "b": "Storage capacity is typically around 1.44MB",
      "c": "Information can be accessed quicker than from magnetic disks",
      "d": "Can store large amounts of data",
      "correct": "b"
    },
    {
      "no": 15,
      "question": "Which of the following provides transmission of data in BOTH directions at the same time?",
      "a": "Half Duplex",
      "b": "Full Duplex",
      "c": "Null Duplex",
      "d": "Simplex",
      "correct": "b"
    },
    {
      "no": 16,
      "question": "In the URL \"http://www.shoes.com ,\"\r\n ‘shoes.com’ stands for the",
      "a": "Extension",
      "b": "Company Name",
      "c": "Domain Name",
      "d": "Internet Company",
      "correct": "c"
    },
    {
      "no": 17,
      "question": "A person who designs, creates and maintains websites is called a",
      "a": "Web Master",
      "b": "Web Manager",
      "c": "Web Browser",
      "d": "Web Tester",
      "correct": "a"
    },
    {
      "no": 18,
      "question": "The introduction of communication by means of computers has REDUCED the employment opportunities of which of the following groups of workers?",
      "a": "Messengers and Postal Workers",
      "b": "Analysts and Programmers",
      "c": "Software Testers and Trainers",
      "d": "Web Masters and Developers",
      "correct": "a"
    },
    {
      "no": 19,
      "question": "Transferring data from a remote computer to your computer is referred to as",
      "a": "Uploading",
      "b": "Browsing",
      "c": "Downloading",
      "d": "Data mining",
      "correct": "c"
    },
    {
      "no": 20,
      "question": "The scrambling of messages at the sending end and unscrambling them at the receiving end is called",
      "a": "Espionage",
      "b": "Encryption",
      "c": "Coding",
      "d": "Eavesdropping",
      "correct": "b"
    },
    {
      "no": 21,
      "question": "The acronym ‘HTTP’ stands for",
      "a": "Hyper Text Transfer Protocol",
      "b": "Hyper Text Transmission Protocol",
      "c": "Higher Text Transmission Protocol",
      "d": "Higher Text Transfer Protocol",
      "correct": "a"
    },
    {
      "no": 22,
      "question": "Which of the following is used to create 2D and 3D Drawings?",
      "a": "CAD",
      "b": "CAE",
      "c": "CAM",
      "d": "CAMM",
      "correct": "a"
    },
    {
      "no": 23,
      "question": "In an email program, the icon above is used to",
      "a": "Send a file",
      "b": "Forward a file",
      "c": "Create a file",
      "d": "Attach a file",
      "correct": "d"
    },
    {
      "no": 24,
      "question": "  Google is an example of",
      "a": "The Internet Explorer",
      "b": "A Web Browser",
      "c": "A Search Engine",
      "d": "The World Wide Web",
      "correct": "c"
    },
    {
      "no": 25,
      "question": "Which of the following is an advantage of using credit or debit cards?",
      "a": "Only the owner can use the card",
      "b": "The owner can spend more money",
      "c": "The owner may use the card to store additional data",
      "d": "The owner can carry less cash",
      "correct": "d"
    },
    {
      "no": 26,
      "question": "Which of the following is detected by using a pressure sensor?",
      "a": "Activity",
      "b": "Light",
      "c": "Warmth",
      "d": "Weight",
      "correct": "d"
    },
    {
      "no": 27,
      "question": "Which of the following is not included in wireless transmission media?",
      "a": "Co-axial",
      "b": "Line- of-Sight",
      "c": "Satellite",
      "d": "Infrared",
      "correct": "a"
    },
    {
      "no": 28,
      "question": "Which of the following is NEITHER Validation NOR a Verification Check?",
      "a": "Storage Media Check",
      "b": "Inconsistency Check",
      "c": "Date-type Check",
      "d": "Range Check",
      "correct": "a"
    },
    {
      "no": 29,
      "question": "Advantages of Information Processing include",
      "a": "Retraining of Staff",
      "b": "Less Human Intervention",
      "c": "Increasing the amount of equipment needed",
      "d": "Reduction of face-to-face interaction",
      "correct": "d"
    },
    {
      "no": 30,
      "question": "Which type of processing system will use sequential file organisation?",
      "a": "Batch Processing",
      "b": "Real-Time Processing",
      "c": "Transaction Processing",
      "d": "Online processing",
      "correct": "b"
    },
    {
      "no": 31,
      "question": "The first-generation programming language is called",
      "a": "Assembly",
      "b": "BASIC",
      "c": "Machine",
      "d": "Non- procedural",
      "correct": "c"
    },
    {
      "no": 32,
      "question": "Which word processing feature must John use if he wants to include his company’s logo in the bottom margin of every page of a document?",
      "a": "Header",
      "b": "Footer",
      "c": "Endnote",
      "d": "Footnote",
      "correct": "b"
    },
    {
      "no": 33,
      "question": "A site map on a web page shows the",
      "a": "Code of the web site",
      "b": "Contents of each web page",
      "c": "Hyperlinks in the web page",
      "d": "List of pages on a web site",
      "correct": "d"
    },
    {
      "no": 34,
      "question": "Which of the following is the correct process for inserting a repeated sentence in different locations of a document?",
      "a": "Copy, Cut and Paste",
      "b": "Cut and paste",
      "c": "Copy and Paste",
      "d": "Cut and Copy",
      "correct": "c"
    },
    {
      "no": 35,
      "question": "  Slides can be arranged using",
      "a": "Slide View",
      "b": "Slide sorter",
      "c": "  Slide Master",
      "d": "New Slide",
      "correct": "c"
    },
    {
      "no": 36,
      "question": "Which of the following may NOT be included in PowerPoint Presentations?",
      "a": "Photos",
      "b": "Text",
      "c": "Chat",
      "d": "Sound",
      "correct": "c"
    },
    {
      "no": 37,
      "question": "Which of the following is the default justification of numbers in spreadsheets?",
      "a": "Left",
      "b": "Full",
      "c": "Centred",
      "d": "Right",
      "correct": "d"
    },
    {
      "no": 38,
      "question": "Which of the following is normally used for website- to-website connection?",
      "a": "Modem",
      "b": "Monitor",
      "c": "Hyperlink",
      "d": "Hypertext",
      "correct": "c"
    },
    {
      "no": 39,
      "question": "Spreadsheet formulae use",
      "a": "Values, Labels or cell addresses",
      "b": "Values, mathematical operators or cell addresses",
      "c": "Labels or cell addresses",
      "d": "Values or Cell addresses",
      "correct": "b"
    },
    {
      "no": 40,
      "question": "Which of the following can be used in a spreadsheet to copy the same number down multiple rows in a single column?",
      "a": "Cursor",
      "b": "Cell",
      "c": "Label",
      "d": "Fill Handle",
      "correct": "a"
    },
    {
      "no": 41,
      "question": "Which of the following characteristics must two database tables have in order for them to be linked?",
      "a": "At least one common attribute name",
      "b": "Composite keys",
      "c": "At least two tuples in the table",
      "d": "The same data in all Tuples",
      "correct": "a"
    },
    {
      "no": 42,
      "question": "You created a spreadsheet with 7 columns and wish to enter the text “WEEKLY REPORT” to appear at the middle of the space used by the 7 columns. Which of the following spreadsheet features will enable this to be done?",
      "a": "Centre",
      "b": "Merging",
      "c": "Merge and Centre",
      "d": "Indenting",
      "correct": "c"
    },
    {
      "no": 43,
      "question": "Which spreadsheet function finds and displays the HIGHEST value in a set of values?",
      "a": "MAX",
      "b": "MIN",
      "c": "RANK",
      "d": "COUNT",
      "correct": "a"
    },
    {
      "no": 44,
      "question": "The object that is used to produce a list of records that meets a particular criterion is a",
      "a": "Form",
      "b": "Table",
      "c": "Module",
      "d": "Query",
      "correct": "d"
    },
    {
      "no": 45,
      "question": "The term used to describe text in a spreadsheet that is aligned towards left by default is",
      "a": "Value formulae",
      "b": "Headings",
      "c": "Labels",
      "d": "Values",
      "correct": "c"
    },
    {
      "no": 46,
      "question": "Consider the following algorithm\r\na= 7\r\nb= 9\r\na= b – a\r\nb = a + b\r\nWrite a, b\r\n\r\nThe output of the algorithm is\r\n",
      "a": "2, 9",
      "b": "2, 11",
      "c": "  7, 9",
      "d": "7, 11",
      "correct": "b"
    },
    {
      "no": 47,
      "question": "Which of the following is NOT a stage/step in problem-solving",
      "a": "Sell the program",
      "b": "Test the program",
      "c": "Define the problem",
      "d": "Evaluate solutions",
      "correct": "a"
    },
    {
      "no": 48,
      "question": "What Pascal statement is used to output results in a program?",
      "a": "Read",
      "b": "Write",
      "c": "Start",
      "d": "Print",
      "correct": "b"
    },
    {
      "no": 49,
      "question": "In the programming language Pascal, which of the following is NOT a standard data type?",
      "a": "Floating Point",
      "b": "Char",
      "c": "Boolean",
      "d": "Integer",
      "correct": "a"
    },
    {
      "no": 50,
      "question": "During the development of a program, writing an algorithm is a step towards",
      "a": "Planning the solution",
      "b": "Defining the problem",
      "c": "Debugging the Program",
      "d": "Maintaining the Program",
      "correct": "a"
    },
    {
      "no": 51,
      "question": "Which of the following is a valid integer constant?",
      "a": "0.37",
      "b": "37.0",
      "c": "370",
      "d": "3700",
      "correct": "b"
    },
    {
      "no": 52,
      "question": "In preparing a computer program , the programmer should FIRST",
      "a": "Plan the solution",
      "b": "Define the problem",
      "c": "Code the progress",
      "d": "Prepare program documentation",
      "correct": "b"
    },
    {
      "no": 53,
      "question": "The purposes of comments in program code are to\r\nI.     Allow persons who read it to follow the process, \r\nII. Allow persons to know the purpose of the program, \r\nIII. Enable persons who read to generate complex codes",
      "a": "I and II only",
      "b": "I and III only",
      "c": "  II and III only",
      "d": "I, II and III",
      "correct": "a"
    },
    {
      "no": 54,
      "question": "The Pascal code var x,y would generate an error. The term used to describe this type of error is",
      "a": "Run time error",
      "b": "Execution Error",
      "c": "Logical Error",
      "d": "Syntax Error",
      "correct": "a"
    },
    {
      "no": 55,
      "question": "What is printed by the pseudocode if the input values are 5 and 2 respectively?\r\nRead A,B\r\nIf A < 2\r\nC  <-- 2\r\n\r\nElse\r\nC <-- 6\r\nEnd If\r\nD <-- C * B + 2\r\n\r\nWrite D",
      "a": "6",
      "b": "12",
      "c": "14",
      "d": "24",
      "correct": "c"
    },
    {
      "no": 56,
      "question": "The following are examples of computer programming instructions:\r\n\"Sort customer by last name Total income by department.\"\r\nTo what generation of programming language do these belong?",
      "a": "First",
      "b": "Second",
      "c": "Third",
      "d": "Fourth",
      "correct": "b"
    },
    {
      "no": 57,
      "question": "Which of the following is not a third generation language?",
      "a": "Assembly",
      "b": "COBOL",
      "c": "C",
      "d": "PASCAL",
      "correct": "a"
    },
    {
      "no": 58,
      "question": "Which of the following is used to perform assignments in the programming language Pascal?\r\n\r\n\r\n\r\n",
      "a": "\"=\"",
      "b": "\"<--\"",
      "c": "\"==\"",
      "d": "\":=\"",
      "correct": "d"
    },
    {
      "no": 59,
      "question": "Which of the following is the Pascal code used to declare an Array WORD with 20 letters?",
      "a": "VAR LIST: ARRAY [20] of char;",
      "b": "WORD : ARRAY [1..20] of char;",
      "c": "VAR WORD : ARRAY [1 to 20] of char;",
      "d": "VAR WORD: ARRAY [1..20] of char;",
      "correct": "d"
    },
    {
      "no": 60,
      "question": "A compiler is a program that converts the",
      "a": "source code to assembly code",
      "b": "source code to machine code",
      "c": "program to output",
      "d": "Program to object code one instruction at a time",
      "correct": "b"
    }
  ];
