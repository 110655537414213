import React from 'react';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import InboxIcon from '@mui/icons-material/Inbox';
import DraftsIcon from '@mui/icons-material/Drafts';
import SendIcon from '@mui/icons-material/Send';
import Alert from '@mui/material/Alert';
import { Link } from 'react-router-dom';
import { useParams } from 'react-router-dom';

/**This component will select the subject area */
export default function SubjectSelector() {
  let { game } = useParams();
  
  return (
    
    <div style={{ width: '100%', maxWidth: 800, margin: 'auto', marginTop:'120px' }}>


      <List component="nav" aria-label="main mailbox folders">  
     
        <ListItemButton component={Link} to={"/gameswitch/"+game+"/it"}  
            sx={{color: '#fff', bgcolor:'#424242',my:1,fontSize:'25px', my: 1,
              '&:hover': {
                backgroundColor: '#757575', // or any color you prefer
              },
            }}>      
          <ListItemIcon>
            <SendIcon sx={{ color: '#fff' }}/>
          </ListItemIcon>
          <ListItemText primary="Information Technology" />
        </ListItemButton>
       
        <ListItemButton component={Link} to={"/gameswitch/"+game+"/maths"} 
            sx={{color: '#fff', bgcolor:'#616161',my:1,fontSize:'25px', my: 1,
              '&:hover': {
                backgroundColor: '#757575', // or any color you prefer
              },
            }}> 
          <ListItemIcon>
            <SendIcon sx={{ color: '#fff' }}/>
          </ListItemIcon>
          <ListItemText primary="Mathematics" />
        </ListItemButton>

        <ListItemButton component={Link} to={"/gameswitch/"+game+"/english"}  
            sx={{color: '#fff', bgcolor:'#424242',my:1,fontSize:'25px', my: 1,
              '&:hover': {
                backgroundColor: '#757575', // or any color you prefer
              },
            }}> 
          <ListItemIcon>
            <SendIcon sx={{ color: '#fff' }}/>
          </ListItemIcon>
          <ListItemText primary="English" />
        </ListItemButton>

        <ListItemButton component={Link} to={"/gameswitch/"+game+"/pob"}  
            sx={{color: '#fff', bgcolor:'#616161',my:1,fontSize:'25px', my: 1,
              '&:hover': {
                backgroundColor: '#757575', // or any color you prefer
              },
            }}> 
          <ListItemIcon>
            <SendIcon sx={{ color: '#fff' }}/>
          </ListItemIcon>
          <ListItemText primary="Principle of Business" />
        </ListItemButton>

        <ListItemButton component={Link} to={"/gameswitch/"+game+"/social"}  
            sx={{color: '#fff', bgcolor:'#424242',my:1,fontSize:'25px', my: 1,
              '&:hover': {
                backgroundColor: '#757575', // or any color you prefer
              },
            }}> 
          <ListItemIcon>
            <SendIcon sx={{ color: '#fff' }}/>
          </ListItemIcon>
          <ListItemText primary="Social Studies" />
        </ListItemButton>

        <ListItemButton component={Link} to={"/gameswitch/"+game+"/iscience"}  
            sx={{color: '#fff', bgcolor:'#616161',my:1,fontSize:'25px', my: 1,
              '&:hover': {
                backgroundColor: '#757575', // or any color you prefer
              },
            }}> 
          <ListItemIcon>
            <SendIcon sx={{ color: '#fff' }}/>
          </ListItemIcon>
          <ListItemText primary="Integrated Science" />
        </ListItemButton>

        


        {/* Add more list items as needed */}
      </List>
    </div>
  );
}
