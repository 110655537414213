import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import InsertEmoticonIcon from '@mui/icons-material/InsertEmoticon';
import GamesSelector from './GamesSelector';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { BrowserRouter as Router, Route, Link, Outlet } from 'react-router-dom';
const pages = ['FAQ', 'About', 'Resources', 'selectsubject'];
const settings = ['Profile', 'Account', 'Dashboard', 'Logout'];

const About = () => <h2>About</h2>;
const Contact = () => <h2>Contact</h2>;

function Home() {
    const [anchorElNav, setAnchorElNav] = React.useState(null);
    const [anchorElUser, setAnchorElUser] = React.useState(null);
  
    const handleOpenNavMenu = (event) => {
      setAnchorElNav(event.currentTarget);
    };
    const handleOpenUserMenu = (event) => {
      setAnchorElUser(event.currentTarget);
    };
  
    const handleCloseNavMenu = () => {
      setAnchorElNav(null);
    };
  
    const handleCloseUserMenu = () => {
      setAnchorElUser(null);
    };
  
    return (
        <>
       
        <AppBar position="fixed" sx={{backgroundColor:'#f3e5f5', color:'#00BFFF'}}>
            <Container maxWidth="xl">
            <Toolbar disableGutters>
                <InsertEmoticonIcon sx={{fontSize:40, color: '#0494c4', display: { xs: 'none', md: 'flex' }, mr: 1 }} />
            
                <Typography
                variant="h4"
                noWrap
                component="a"
                href="#app-bar-with-responsive-menu"
                sx={{
                    mr: 2,
                    display: { xs: 'none', md: 'flex' },
                    fontFamily: 'monospace',
                    fontWeight: 900,
                    letterSpacing: '.3rem',
                    color: '#0494c4',
                    textDecoration: 'none',
                }}
                >
                WizQuizTT
                </Typography>
    
                <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
                <IconButton
                    size="large"
                    aria-label="account of current user"
                    aria-controls="menu-appbar"
                    aria-haspopup="true"
                    onClick={handleOpenNavMenu}
                    color="#0494c4"                
                >
                    <MenuIcon />
                </IconButton>
                <Menu
                    id="menu-appbar"
                    anchorEl={anchorElNav}
                    anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                    }}
                    keepMounted
                    transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                    }}
                    open={Boolean(anchorElNav)}
                    onClose={handleCloseNavMenu}
                    sx={{
                    display: { xs: 'block', md: 'none' },
                    }}
                >
                    
                    <MenuItem onClick={handleCloseNavMenu} className='MenuItem' >
                        {/* <Typography textAlign="center" >{page}</Typography> */}
                        <Link to="/faq">{"FAQ"}</Link>
                    </MenuItem>
                    <MenuItem onClick={handleCloseNavMenu} className='MenuItem' >
                        {/* <Typography textAlign="center" >{page}</Typography> */}
                        <Link to="/resources">{"Resources"}</Link>
                    </MenuItem>
                    <MenuItem onClick={handleCloseNavMenu} className='MenuItem'>
                        {/* <Typography textAlign="center" >{page}</Typography> */}
                        <Link to="/selectgame">{"Games"}</Link>
                    </MenuItem>
                </Menu>
                </Box>

                <InsertEmoticonIcon sx={{fontSize:40, color: '#0494c4', display: { xs: 'flex', md: 'none' }, mr: 1 }} />
            
                <Typography
                variant="h4"
                noWrap
                component="a"
                href="#app-bar-with-responsive-menu"
                sx={{
                    mr: 2,
                    display: { xs: 'flex', md: 'none' },
                    flexGrow: 1,
                    fontFamily: 'monospace',
                    fontWeight: 900,
                    letterSpacing: '.3rem',
                    color: '#0494c4',
                    textDecoration: 'none',
                }}
                >
                WizQuizTT
                </Typography>

                <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
               
                     <Link to="/faq">
                        <Button onClick={handleCloseNavMenu} className='MenuBtn'>
                        {"FAQ"}
                        </Button>
                    </Link>
               
                    <Link to="/resources">
                        <Button onClick={handleCloseNavMenu} className='MenuBtn'>
                        {"Resources"}
                        </Button>
                    </Link>

                    <Link to="/selectgame">
                        <Button onClick={handleCloseNavMenu} className='MenuBtn'>
                        {"Games"}
                        </Button>
                    </Link>
                </Box>
    
                <Box sx={{ flexGrow: 0 }}>
                <Tooltip title="Open settings">
                    <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                    <AccountCircleIcon sx={{fontSize:40, color: '#0494c4',  mr: 1 }} />
                    </IconButton>
                </Tooltip>

                <Menu
                    sx={{ mt: '45px' }}
                    id="menu-appbar"
                    anchorEl={anchorElUser}
                    anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                    }}
                    keepMounted
                    transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                    }}
                    open={Boolean(anchorElUser)}
                    onClose={handleCloseUserMenu}
                >
                    

                    <MenuItem onClick={handleCloseNavMenu} className='MenuItem' >
                        {/* <Typography textAlign="center" >{page}</Typography> */}
                        <Link to="/profile">{"Profile"}</Link>
                    </MenuItem>
                    <MenuItem onClick={handleCloseNavMenu} className='MenuItem' >
                        {/* <Typography textAlign="center" >{page}</Typography> */}
                        <Link to="/account">{"Account"}</Link>
                    </MenuItem>                   
                    <MenuItem onClick={handleCloseNavMenu} className='MenuItem' sx={{ '&:hover': { backgroundColor: '#ddd' }}}>
                        {/* <Typography textAlign="center" >{page}</Typography> */}
                        <Link to="/logout">{"Logout"}</Link>
                    </MenuItem>
                
                </Menu>
                </Box>
            </Toolbar>
            </Container>
        </AppBar>
     
       
         <Outlet/>
       
      </>
    );
  }
  export default Home;
