import React,{useEffect} from 'react'
import {useSelector} from 'react-redux'
import LoginForm from './Login';
import Authorizer from './Authorizer';

export default function EntryRouter() {
  useEffect(()=>{
  },[]);


  const isLoggedIn = useSelector((state) => state.userReducer.isLoggedIn);
    return (
        <div style={{padding:0, margin:0}}>
           {
                isLoggedIn === true?              
                   <Authorizer/>      
                :
                <LoginForm/>
            }

        </div>

    )
}
