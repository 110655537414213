import React from 'react';
import { Card, CardContent,CardMedia,Grid,Typography,Button,Box } from '@mui/material';
import Container from '@mui/material/Container';
import { Link } from 'react-router-dom';
import '../../../game.css';

/**this component is to selec which Game To Play */
export default function GamesSelector() {
  return (
    <Container maxWidth="lg"  justifyContent="center"  id="game-selector-container" >
   
          <Grid item xs={12} sm={6} md={4} lg={3} style={{backgroundColor:'#fff', height:'300px'}}>
             <Link to="/selectsubject/quizzyquest">             
                <Button  style={{ textDecoration: 'none', color: 'inherit', padding:0  }}>
                        <Card  className="CategoryCards"   >
                            <Box className="purple-gradient gameselectorcardstyle" >
                                <Typography variant="h5" component="div">
                                    QuizyQuest
                                </Typography>
                            </Box>
                                                    
                            
                            <CardContent >
                          
                            <Typography variant="body2">
                                Answer as much questions s you can from various topic, to test your knowlede
                            </Typography>
                            </CardContent>
                        </Card>
                </Button>       
              </Link>

          </Grid>

          <Grid item xs={12} sm={6} md={4} lg={3} style={{backgroundColor:'#fff', height:'300px'}}>
              <Link to="/selectsubject/quizcards">
              <Button  style={{ textDecoration: 'none', color: 'inherit', padding:0  }}>
                        <Card  className="CategoryCards">
                            <Box className="blue-gradient gameselectorcardstyle" >
                                <Typography variant="h5" component="div">
                                   QuizCards
                                </Typography>
                            </Box>                        
                            
                            <CardContent >
                            
                            <Typography variant="body2">
                                Answer as much questions s you can from various topic, to test your knowlede
                            </Typography>
                            </CardContent>
                        </Card>
                </Button> 
              </Link>
          </Grid>

          <Grid item xs={12} sm={6} md={4} lg={3} style={{backgroundColor:'#fff', height:'300px'}}>
              <Link to="/selectsubject/trivia">
              <Button  style={{ textDecoration: 'none', color: 'inherit', padding:0  }}>
                        <Card  className="CategoryCards">
                            <Box className="green-gradient gameselectorcardstyle" >
                                    <Typography variant="h5" component="div">
                                        Trivia
                                    </Typography>
                                </Box>                        
                            
                            <CardContent >
                           
                            <Typography variant="body2">
                                Answer as much questions s you can from various topic, to test your knowlede
                            </Typography>
                            </CardContent>
                        </Card>
                </Button>   
              </Link>     
          </Grid>

          <Grid item xs={12} sm={6} md={4} lg={3} style={{backgroundColor:'#fff', height:'300px'}}>
              <Link to="/selectsubject/peekaboo">
              <Button  style={{ textDecoration: 'none', color: 'inherit', padding:0  }}>
                        <Card  className="CategoryCards">
                             <Box className="yellow-gradient gameselectorcardstyle" >
                                    <Typography variant="h5" component="div">
                                        Peekaboo
                                    </Typography>
                                </Box>                       
                            
                            <CardContent >
                           
                            <Typography variant="body2">
                                Answer as much questions s you can from various topic, to test your knowlede
                            </Typography>
                            </CardContent>
                        </Card>
                </Button>  
              </Link>      
          </Grid>

          <Grid item xs={12} sm={6} md={4} lg={3} style={{backgroundColor:'#fff', height:'300px'}}>
             <Link to="/selectsubject/ludo">
             <Button  style={{ textDecoration: 'none', color: 'inherit', padding:0  }}>
                        <Card  className="CategoryCards">
                            <Box className="pink-gradient gameselectorcardstyle" >
                                    <Typography variant="h5" component="div">
                                        Ludo
                                    </Typography>
                                </Box>                          
                            
                            <CardContent >
                            
                            <Typography variant="body2">
                                Answer as much questions s you can from various topic, to test your knowlede
                            </Typography>
                            </CardContent>
                        </Card>
                </Button>   
              </Link>     
          </Grid>

          <Grid item xs={12} sm={6} md={4} lg={3} style={{backgroundColor:'#fff', height:'300px'}}>
             <Link to="/selectsubject/plinko">
             <Button  style={{ textDecoration: 'none', color: 'inherit', padding:0  }}>
                        <Card  className="CategoryCards">
                           <Box className="orange-gradient gameselectorcardstyle" >
                                    <Typography variant="h5" component="div">
                                        Plinko
                                    </Typography>
                                </Box>                        
                            
                            <CardContent >
                            
                            <Typography variant="body2">
                                Answer as much questions s you can from various topic, to test your knowlede
                            </Typography>
                            </CardContent>
                        </Card>
                </Button>     
              </Link>   
          </Grid>        

  </Container>
  )
}
