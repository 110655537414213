export const userData = [
    {
      "email": "ryan.rennie@hotmail.com",
      "password": "1234567",
      "role":"admin"
    },
    {
        "email": "student@hotmail.com",
        "password": "1234567",
        "role":"student"
      },
]