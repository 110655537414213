import React,{useState,useEffect,useRef} from 'react';
import './lifebar.css';

export default function LifeBar({MinusLife}) {
    const [life, setLife] = useState(100);
    const lifeRef = useRef(null);

    useEffect(()=>{
        if(MinusLife > 60){
            lifeRef.current.classList.add('slow-glow');
            lifeRef.current.classList.remove('medium-glow');
            lifeRef.current.classList.remove('fast-glow');
        }
        if(MinusLife < 60 ){
            lifeRef.current.classList.add('medium-glow');
            lifeRef.current.classList.remove('fast-glow');
            lifeRef.current.classList.remove('slow-glow');

        }
        if(MinusLife < 40){
            lifeRef.current.classList.add('fast-glow');
            lifeRef.current.classList.remove('slow-glow');
            lifeRef.current.classList.remove('medium-glow');

        }

        setLife(MinusLife);
    },[MinusLife]);

  return (
    <div className="life-bar" ref={lifeRef}>
    <div className="life-fill" style={{width:life+'%'}}></div>
  </div>
  )
}
