import React from 'react';
import { Typography, Grid, Card, CardContent } from '@mui/material';
import './resource.css';

export default function Resources() {
  return (
    <div style={{ padding: '20px' }}>
    <Typography variant="h4" gutterBottom>
      Resources
    </Typography>
    <Grid container spacing={3}>
      {/* Example resource cards */}
      <Grid item xs={12} sm={6} md={4}>
        <Card className='resource-card' style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
          <CardContent>
            <div className='resource-card-img-div'>
              <img src="/ITCard.JPG" alt="Example" />
            </div>
            <Typography gutterBottom variant="h5" component="h2">
              Information Technology
            </Typography>
            <Typography variant="body2" color="textSecondary" component="p">
            Unlocking Potential, One Mind at a Time: Nurturing Growth Through Education.
            </Typography>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <Card className='resource-card' style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
          <CardContent>
            <div className='resource-card-img-div'>
              <img src="/MathCard.JPG" alt="Example" />
            </div>
            <Typography gutterBottom variant="h5" component="h2">
              Mathematics
            </Typography>
            <Typography variant="body2" color="textSecondary" component="p">
              Where Numbers Speak Louder Than Words: Discover the Beauty of Mathematics
            </Typography>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <Card className='resource-card' style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
          <CardContent>
              <div className='resource-card-img-div'>
              <img src="/EnglishCard.JPG" alt="Example" />
            </div>
            <Typography gutterBottom variant="h5" component="h2">
              English
            </Typography>
            <Typography variant="body2" color="textSecondary" component="p">
              Crafting Communication, Inspiring Expression: English Language Mastery Begins Here
            </Typography>
          </CardContent>
        </Card>
      </Grid>
      {/* Add more resource cards as needed */}
    </Grid>
  </div>
  )
}
