import React,{useState,useRef,useEffect } from 'react'
import { Button } from '@mui/material';
import ThumbUpOffAltIcon from '@mui/icons-material/ThumbUpOffAlt';
import CircularProgressWithLabel from '../../Reusable/CircularProgressWithLabel';

export default function Question({Question,setScore,nextQuestionPlease, MaxQuestionValue}) {
  const [selectedAnswer, setSelectedAnswer] = useState('');
  const [message, setMessage] = useState('');
  const [nextMove, setNextMove] = useState(nextQuestionPlease);
  const [timer, setTImer] = useState(0);
  const [running, setRunning] = useState(true);

  const myRefA = useRef(null);
  const myRefB = useRef(null);
  const myRefC = useRef(null);
  const myRefD = useRef(null);
  
  useEffect(() => {   
    /**remove Class List */
    setSelectedAnswer('');
    setTImer(0);
    setMessage('');
    setRunning(true);
    myRefA.current.classList.remove('strikethrough');
    myRefB.current.classList.remove('strikethrough');
    myRefC.current.classList.remove('strikethrough');
    myRefD.current.classList.remove('strikethrough');
    myRefA.current.classList.remove('correct-answer-highlighted');
    myRefB.current.classList.remove('correct-answer-highlighted');
    myRefC.current.classList.remove('correct-answer-highlighted');
    myRefD.current.classList.remove('correct-answer-highlighted');
  }, [Question]); 

  useEffect(() => {

    if (running ) {
          const countdown = setInterval(() => {
            setTImer(prevSeconds => {        
                  if (prevSeconds === MaxQuestionValue ) {                      
                        setMessage("Sorry! Time is up.");
                        setNextMove(true);
                        clearInterval(countdown); // Stop the countdown when it reaches zero
                        setRunning(false);
                        /**if question not answered move on to the next question */
                        return prevSeconds;
                    }
                  return prevSeconds + 1; // Decrease the countdown by 1 second          
            });

          }, 1000);
        return () => clearInterval(countdown); // Cleanup function to clear the interval when the component unmounts

       }

  }, [running, timer]);

 

  const handleAnswerChange = (event) => {
    setSelectedAnswer(event.target.value);
  };

  const submitAnswer = () => {  
    setRunning(prevRunning => !prevRunning);
   
    /**strikethrough if answer wrond */
    if(selectedAnswer === 'a' && Question.correct != 'a'){
      myRefA.current.classList.toggle('strikethrough');
    }
    if(selectedAnswer === 'b' && Question.correct != 'b'){
      myRefB.current.classList.toggle('strikethrough');
    }
    if(selectedAnswer === 'c' && Question.correct != 'c'){
      myRefC.current.classList.toggle('strikethrough');
    }
    if(selectedAnswer === 'd' && Question.correct != 'd'){
      myRefD.current.classList.toggle('strikethrough');
    }
      
     if(selectedAnswer === Question.correct){       
             setMessage('Great Job');
      }else{
        setMessage('Sorry! Keep Trying');
        /**hilight correct answer only if answer is not correct */
        if(Question.correct === 'a'){
          myRefA.current.classList.toggle('correct-answer-highlighted');
        }
        if(Question.correct === 'b'){
          myRefB.current.classList.toggle('correct-answer-highlighted');
        }
        if(Question.correct === 'c'){
          myRefC.current.classList.toggle('correct-answer-highlighted');
        }
        if(Question.correct === 'd'){
          myRefD.current.classList.toggle('correct-answer-highlighted');
        }
      }    
      setNextMove(true);
   };

   const NextQuestion = () => {
    /**increase answer and move on to next question */
    var points = 0;
    
      if(selectedAnswer === Question.correct ){
        points = 1;       
      }
    
    setScore(points);
    setSelectedAnswer('');
    setNextMove(false);
   };
   

  return (

    <div className="card ">
       <div className='card-header'>
         <p className="card-text">{Question.question}</p>  
         <div className='timer-div'>
             <CircularProgressWithLabel value={timer} MaxQuestionValue={MaxQuestionValue}/>     
         </div>
      </div>

          <div className='card-message'> 
                <label style={{color:'red'}}>{message}</label>         
          </div>

    <div className="card-body ">
   
           <div className="form-check custom-radio blue">
                <input
                  className="form-check-input"
                  type="radio"
                  name="answer"
                  id="a"
                  value="a"
                  checked={selectedAnswer === "a"}
                  onChange={handleAnswerChange}
                />
                <label className="form-check-label" htmlFor="a" ref={myRefA}>
                <div className='bulletpointblue blueselected'></div>{Question.a}
                
                </label>
            </div>  

            <div className="form-check custom-radio red">
                <input
                  className="form-check-input"
                  type="radio"
                  name="answer"
                  id="b"
                  value="b"
                  checked={selectedAnswer === "b"}
                  onChange={handleAnswerChange}
                />
                <label className="form-check-label" htmlFor="b" ref={myRefB}>
                <div className='bulletpointred redselected'></div>{Question.b}
                </label>
            </div>        

            <div className="form-check custom-radio green">
                <input
                  className="form-check-input"
                  type="radio"
                  name="answer"
                  id="c"
                  value="c"
                  checked={selectedAnswer === "c"}
                  onChange={handleAnswerChange}
                />
                <label className="form-check-label greenlabel" htmlFor="c" ref={myRefC}>
                <div className='bulletpointgreen greenselected'></div>{Question.c}
                </label>
            </div>       
            
            <div className="form-check custom-radio yellow">
                <input
                  className="form-check-input"
                  type="radio"
                  name="answer"
                  id="d"
                  value="d"
                  checked={selectedAnswer === "d"}
                  onChange={handleAnswerChange}
                />
                <label className="form-check-label" htmlFor="d" ref={myRefD}>
                <div className='bulletpointyellow yellowselected'></div>{Question.d}
                </label>
            </div>       
                 
    </div>

     <div className='card-footer'>
   
     
            {nextMove?
            <Button onClick={NextQuestion} variant="contained" color="primary" className='glow-button'>
              Next Question
            </Button>
              :
              <Button onClick={submitAnswer} variant="contained" color="success">
               Submit Answer
            </Button>
          }
             
      </div> 
  </div>

  )
}
