import React,{useEffect} from 'react';
import { sign_out_user,set_current_user } from '../../context/redux/user/userSlice';
import { useDispatch } from 'react-redux';

import {Navigate} from "react-router-dom";

export default function Logout() {
    const dispatch = useDispatch();

    useEffect(()=>{
        SignOut()
    },[])

    function SignOut(){
        localStorage.clear();
         //dispatch(set_current_user({}))
         dispatch(sign_out_user());        
    }
    
    return(
        <Navigate to="/" replace />
    )
  
    
}
