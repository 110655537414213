import React from 'react';
import {
  Avatar,
  Button,
  Container,
  Grid,
  Paper,
  Typography,
} from '@mui/material';

export default function Account() {
  return (
    <Container style={{ marginTop: '100px' }}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Paper style={{ padding: '20px', textAlign: 'center' }}>
            
            <Typography variant="h5" gutterBottom>
              John Doe
            </Typography>
            <Typography variant="subtitle1" gutterBottom>
              john.doe@example.com
            </Typography>
            <Button variant="contained" color="primary">
              Edit Profile
            </Button>
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <Paper style={{ padding: '20px', textAlign: 'center' }}>
            <Typography variant="h6" gutterBottom>
              Account Information
            </Typography>
            <Typography variant="body1" gutterBottom>
              Username: johndoe123
            </Typography>
            <Typography variant="body1" gutterBottom>
              Member Since: January 1, 2020
            </Typography>
            <Typography variant="body1" gutterBottom>
              Last Login: April 20, 2024
            </Typography>
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <Paper style={{ padding: '20px', textAlign: 'center' }}>
            <Typography variant="h6" gutterBottom>
              Preferences
            </Typography>
            <Typography variant="body1" gutterBottom>
              Receive newsletter: Yes
            </Typography>
            <Typography variant="body1" gutterBottom>
              Email notifications: Yes
            </Typography>
          </Paper>
        </Grid>
      </Grid>
    </Container>
  )
}
