import {configureStore,combineReducers } from '@reduxjs/toolkit';
import userReducer from './user/userSlice';
import storage from 'redux-persist/lib/storage';
import { persistReducer, persistStore } from 'redux-persist';

const persisConfig ={
    key:'root',
    storage
}

const reducer =  combineReducers({
    userReducer
  });

const persistedReducer = persistReducer(persisConfig,reducer);

export const store = configureStore({
    reducer: persistedReducer,
    devTools: process.env.NODE_ENV !== 'production'
  });
  
export const persistor = persistStore(store);