import React, { useEffect, useState } from 'react';
// import 'bootstrap/dist/css/bootstrap.min.css';
import './quiz.css';
import { quizData } from '../../../../src/data/quizdata';
import Question from './Question';
import ExcelToJsonConverter from '../../../components/ExcelUploadFunctions/ExcelToJsonConverter';
import { Box,Grid, Paper,Button } from '@mui/material';
import LifeBar from '../../Reusable/LifeBar';


function QuizGamePlay({Subject,SubjectLabel}) {
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [playerScore, setPlayerScore] = useState(0);
  const [playerLife, setPlayerLife] = useState(100);
  
  const [correctQeustionArray, setCorrectQeustionArray] = useState([]);
  const [failedQuestionArray, setfailedQuestionArray] = useState([]);
  const [gameNotOver, setGameOver] = useState(false);
  const [gameCompleted, setGameCompleted] = useState(false);
  const timeToAnswerQuestion = 20;

  /**timer */
  const [nextQuestionPlease, setNextQuestionPlease] = useState(false);  

  useEffect(() => {   
   if (playerLife === 0) {     
        setGameCompleted(prevState => !prevState);       
   }
   }, [playerLife]);

  useEffect(() => {   
   if (gameNotOver === true) {     
      setGameOver(false);
      setGameCompleted(false);   
      setPlayerScore(0);
      setPlayerLife(100);
      setCurrentQuestion(0);
      setCorrectQeustionArray([]);
      setfailedQuestionArray([]);
      setNextQuestionPlease(false);
  }
  }, [gameNotOver]);
   
    const setScore = (value) =>{  
    
    if(value === 1){
      /**answer was correct */
      setPlayerScore(previousScore => previousScore + value);
      /**set answered array */  
      setCorrectQeustionArray(prevItems => [...prevItems, quizData[currentQuestion]]);
    }else{
       /**answer was not correct */
       /**reduce life */
       setPlayerLife(previousNum => previousNum - 20)
      /**set failedQuestionArray array */
      setfailedQuestionArray(prevItems => [...prevItems, quizData[currentQuestion]]);
    }
 
    if(currentQuestion +1 >= quizData.length){
      setGameCompleted(prevState => ! prevState);   
      return;  
    }else{
      /**next question */
      setCurrentQuestion(previousNum => previousNum +1);
    }   
  }

  
  if( gameCompleted === false ){
    return (  
        <div className='game-space-parent-div'>  
            <h1 className='game-space-parent-div-label'>{SubjectLabel}</h1>

            <Box   className='game-space'>
                {/* <ExcelToJsonConverter /> */}
                <Grid container  style={{padding:'15px'}}>
                {/* First column */}
                <Grid item xs={8} style={{display:'flex',justifyContent:'center', padding:'5px'}}>
                  <LifeBar MinusLife={playerLife}/>
                </Grid>
                {/* Third column */}
                <Grid item xs={4}>
                  <div className="score-div" >{playerScore}</div>
                </Grid>
              </Grid>

                <Question Question={quizData[currentQuestion]} setScore={setScore} nextQuestionPlease={nextQuestionPlease} MaxQuestionValue={timeToAnswerQuestion}/> 

                <span className='quenstion-number'> {"Total answered:"}{currentQuestion} </span> 
           </Box>
       </div> 
     );
  }

  if( gameCompleted === true ){
    return (  <Box className='game-over-space' >
          <h1>{"Game Over"}</h1>
          <h2 id="score">Player Score:{" "}{playerScore}</h2>
         
        {
        failedQuestionArray.length > 0 ?<div id='failed-question-div'>
                <table className="tblstyle ">
                        <thead>
                          <tr>
                            <th scope="col">Question you could'nt answer</th>
                            <th scope="col">Correct Answer</th>       
                          </tr>
                        </thead>
                        <tbody>

                        {failedQuestionArray.map((item, index) => (
                          <tr key={index}>
                            <td>{item.question}</td>
                            <td>{item[item.correct]}</td>
                          </tr>
                        
                        ))}

                        </tbody>
                      </table></div>
                :null
        }         
       <Button style={{marginTop:'25px', float:'right'}} onClick={() => setGameOver(prevState => !prevState)} variant="contained" color='warning' className='glow-dark-button'>New Game</Button>

       </Box>
     );
  }
}

export default QuizGamePlay;
