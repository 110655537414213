import React,{useState} from 'react';
import { useParams } from 'react-router-dom';
import QuizGamePlay from './QuzzyQuest/QuizGamePlay';
export default function GameSwitch() {
    let { game,subject } = useParams();

    let subjectLabel = "";
    if (subject === 'it'){
        subjectLabel = "Information Technology";
    }
    if (subject === 'maths'){
       subjectLabel = "Mathematics";
    }

    if (subject === 'english'){
       subjectLabel = "English";
    }

    if (subject === 'pob'){
       subjectLabel = "Principle of Business";
    }

    if (subject === 'social'){
       subjectLabel = "Social Studies";
    }

    if (subject === 'iscience'){
       subjectLabel = "Integrated Science";
    }

    


    if(game === "quizzyquest"){
       return( 
        <div style={{display:'flex', justifyContent:'center', alignItems:'center'}}>
            <QuizGamePlay Subject={subject} SubjectLabel={subjectLabel} />
       </div>
    
    );
    }

    if(game === "quizcards"){
        return( 
            <div style={{margin:'auto', padding:'60px', backgroundColor:'#fff', width:'80%', textAlign:'center'}}> 
                <h1 style={{fontWeight:'bold'}}>{"QuizCards Comming soon"}</h1>
            </div>
        );
    }

    if(game === "trivia"){
        return( 
            <div style={{margin:'auto', padding:'60px', backgroundColor:'#fff', width:'80%', textAlign:'center'}}> 
               <h1 style={{fontWeight:'bold'}}>{"Trivia Comming soon"}</h1>
           </div>
        );
    }

    if(game === "peekaboo"){
        return( 
            <div style={{margin:'auto', padding:'60px', backgroundColor:'#fff', width:'80%', textAlign:'center'}}> 
                <h1 style={{fontWeight:'bold'}}>{"Peekaboo Comming soon"}</h1>
            </div>
        );

    }

    if(game === "ludo"){
        return( 
            <div style={{margin:'auto', padding:'60px', backgroundColor:'#fff', width:'80%', textAlign:'center'}}> 
                <h1 style={{fontWeight:'bold'}}>{"Ludo Comming soon"}</h1>
            </div>
        );

    }

    if(game === "plinko"){
        return( 
            <div style={{margin:'auto', padding:'60px', backgroundColor:'#fff', width:'80%', textAlign:'center'}}> 
                <h1 style={{fontWeight:'bold'}}>{"Plinko Comming soon"}</h1>
            </div>
        );

    }
  
}
