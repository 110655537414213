import React,{useEffect, useState} from 'react'
import {useSelector} from 'react-redux'
import { useDispatch } from 'react-redux';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Dashboard from '../pages/Admin/Dachboard';
import Home from '../pages/Home/Home';
import SubjectSelector from '../pages/Home/SubjectSelector';
import GamesSelector from '../pages/Home/GamesSelector';
import Faq from '../pages/Home/Faq';
import Resources from '../pages/Home/Resources';
import Profile from '../pages/Home/Profile';
import Logout from '../Auth/Logout';
import Account from '../pages/Home/Account';
import GameSwitch from '../Games/GameSwitch';

export default function Authorizer() {
  const dispatch = useDispatch();
  const user_role = useSelector((state) => state.userReducer.currentUser.role);
  const [loggedIn, setLogin] = useState(true);

useEffect(()=>{
    localStorage.setItem("ExpiryTime", Date.now()+60000);
    const interval = setInterval(checkForInactivity, 5000);
    return ()=> clearInterval(interval);
},[]);

useEffect(()=>{
  const controller = new AbortController();
    window.addEventListener("click", updateExpiryTime);
    window.addEventListener("keypress", updateExpiryTime);
    window.addEventListener("scroll", updateExpiryTime);
    window.addEventListener("mouseover", updateExpiryTime);
    //aborts the request when the component umounts
    return () => controller?.abort();
   
},[]);

const checkForInactivity = () =>{
    const expirationCheck = localStorage.getItem("ExpiryTime");
    if(expirationCheck < Date.now()){     
        /**logout */    
       /*  setLogin(false);
         localStorage.clear();
         dispatch(sign_out_user()); */
    }
}

const updateExpiryTime = () =>{ 
   checkForInactivity();
   if(loggedIn){
      const newExpirationTime = Date.now() + 60000;
      localStorage.setItem("ExpiryTime", newExpirationTime);
     // console.log(newExpirationTime);
  }
}

if(user_role === "admin"){
  return (
      <Dashboard />
    )
}

if(user_role === "student"){
  return (
    <BrowserRouter>
        <Routes>
          <Route path="" element={<Home />}> 
          <Route exact path="/" element={<Home />} />    
          <Route path="faq" element={<Faq />} />    
          <Route path="resources" element={<Resources />} />    
          <Route path="profile" element={<Profile />} />    
          <Route path="account" element={<Account />} />    
          <Route path="dashboard" element={<Dashboard />} />    
          <Route path="logout" element={<Logout />} />    

          <Route path="selectsubject/:game" element={<SubjectSelector />} />  
          <Route path="selectgame" element={<GamesSelector />} />   
          <Route path="gameswitch/:game/:subject" element={<GameSwitch />} />   


        </Route>    
      </Routes>
   </BrowserRouter>


    )
}


}

