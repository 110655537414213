import React,{useState} from 'react';
import './FAQPage.css';
import { Accordion, AccordionSummary, AccordionDetails, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import Divider from '@mui/material/Divider';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
const faqData = [
  {
    question: 'What is QuizyQuest and how to play?',
    answer:
      'QuizyQuest is a questionaire style game with many levels. Each level has twenty question. inorder to advance to the next level you must correctly answer fifteen or more of the questions',
  },
  {
    question: 'What is QuizCards and how to play?',
    answer:
      'QuizCards presents players with twenty Cards that has twenty riddles. players must solve all riddles before advancing to the next round.',
  },
  {
    question: 'What is Trivia and how to play?',
    answer:
      'Spin the Trivia wheel and answer the question you landed on. These question are fill in the blanks. You must answer all to advance to the next round',
  },
  {
    question: 'What is Peekaboo and how to play?',
    answer:
      'Peekaboo presents a question which the you must memorise, then four possible answers that are hidden after five seconds. then gives you the option to select the right number or letter associated with the question you believe is right',
  },
  {
    question: 'What is Ludo and how to play?',
    answer:
      'Answer the questions to generate spins of a dice, and move your token the corresponding number of steps reflected on the dice until you reach the home area. If you answer correctly you get a number between three and six, if you get a six you get an extra turn, If you land on a space occupied by an opponents token, you bump that token back to its starting area',
  },
  {
    question: 'What is Plinko and how to play?',
    answer:
      'Drop the disc or chip down a pegged board, where it bounces around randomly before landing in one of several slots containing a question or riddle, each with a different points.',
  },
];



export default function Faq() {
  const [expandedIndex, setExpandedIndex] = useState(null);

  const handleAccordionClick = (index) => {
    setExpandedIndex(expandedIndex === index ? null : index);
  };
  
  return (
    <div className="faq-container">
      <h1>{"Frequently Asked Questions"}</h1>
      <Divider sx={{color:'#eeff41'}}>
       <HelpOutlineIcon fontSize="large" sx={{color:'#eeff41'}}/>
      </Divider>
    {faqData.map((faq, index) => (
      <div key={index} className="faq-item">
        <div className="faq-question" onClick={() => handleAccordionClick(index)}>
          {faq.question}
          <span className={`chevron-circle ${expandedIndex === index ? 'open' : ''}`}>
             {expandedIndex === index ? <ExpandMoreIcon /> : <KeyboardArrowRightIcon />}
          </span>
        </div>
        {expandedIndex === index && (
          <div className="faq-answer">{faq.answer}</div>
        )}
      </div>
    ))}
  </div>
  );
  
}
