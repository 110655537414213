import { createSlice } from "@reduxjs/toolkit";

const userSlice = createSlice({
    name:'user',
    initialState:{
        isLoggedIn: false,
        currentUser:null,
        roles: []
    },
    reducers:{
        set_current_user: (state, action) =>{
            
            /**extract departments and permissions */           
            console.log(action.payload)
            const userRoles = action.payload.roles                       

            return{
               ...state,
                isLoggedIn : true,
                currentUser: action.payload,
                roles: userRoles
            }
        },
        sign_out_user:(state)=>{
            return{
                ...state,
                isLoggedIn:false,
                currentUser:null,
                roles:null
            }
        }
        , set_user_access:(state,roles)=>{
            return{
                ...state,
                roles:roles
            }
        }
    }
});

// methods for dispatch
export const {set_current_user, sign_out_user,set_user_access, initialState} = userSlice.actions;

// reducer to configureStore
export default userSlice.reducer;